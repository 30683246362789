<template>
  <component
    :is="tag"
    :id="buttonId"
    ref="button"
    :class="removeClassExtras(`
      ${component.size}
      ${component.state}
      ${full ? 'w-full justify-center' : ''}
      ${to ? 'inline-flex' : 'flex'}
      ${ticker
        ? `before:content-[''] before:w-[1.25rem] before:h-[1.25rem] before:border before:border-gray-400 before:rounded-full before:mr-2.5 before:relative enabled:hover:before:border-white enabled:hover:before:bg-[url('~/public/icons/check.svg')] before:bg-no-repeat before:bg-center before:inline-block`
        : ''
    }
    `)"
    class="group text-dark empty:hidden items-center justify-center cursor-pointer"
    v-bind="attributes"
    @click="handleClick"
  >
    <slot v-if="$slots.default" />

    <IconsChevron
      v-if="hasIcon"
      ref="icon"
      class="fill-dark group-active:fill-dark group-hover:fill-white ml-3 group-disabled:fill-gray-40"
      :class="iconClass"
      :width="component.arrowSize.width"
      :height="component.arrowSize.height"
      :direction="iconDirection"
    />
  </component>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

const { $tracking } = useNuxtApp()
const $attrs = useAttrs()
const $router = useRouter()

defineOptions({
  name: 'AtomsButton'
})

const props = defineProps({
  size: {
    type: String,
    default: 'small',
    validator: value => ['extra small', 'small', 'medium', 'large'].includes(value)
  },

  type: {
    type: String,
    default: 'button',
    validator: value => ['button', 'submit'].includes(value)
  },

  theme: {
    type: String,
    default: 'primary',
    validator: value => ['primary', 'secondary', 'secondary2', 'secondaryBlue', 'secondaryWithImage', 'tertiary', 'link'].includes(value)
  },

  hasIcon: {
    type: Boolean,
    default: false
  },

  iconDirection: {
    type: String,
    default: 'right',
    validator: value => ['left', 'right', 'top', 'bottom'].includes(value)
  },

  iconSize: {
    type: Object,
    default: () => ({})
  },

  iconClass: {
    type: String,
    default: ''
  },

  full: {
    type: Boolean,
    default: false
  },

  enableTracking: {
    type: Boolean,
    default: true
  },

  to: {
    type: String,
    default: ''
  },

  ticker: {
    type: Boolean,
    default: false
  },

  bgColor: {
    type: String,
    default: '',
    validator: value => ['', 'pink', 'blue'].includes(value)
  },

  disableTheme: {
    type: String,
    default: 'default',
    validator: value => ['default', 'light'].includes(value)
  },

  crossApp: {
    type: Boolean,
    default: false
  },

  trackingAdditionalParams: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const tag = computed(() => {
  const to = props.to

  if ((
    to &&
    (
      to.includes('tel:') ||
      to.includes('mailto:') ||
      to.includes('http') ||
      to.includes('www')
    )) ||
    props.crossApp
  ) {
    return 'a'
  }

  return to ? 'nuxt-link' : 'button'
})

const attributes = computed(() => {
  const to = props.to

  const attrs = {
    ...((!_isEmpty($attrs) && {
      ...$attrs,
      type: props.type
    }) || {})
  }

  if (tag.value === 'a') {
    attrs.href = to
  } else if (tag.value === 'nuxt-link') {
  /**
   * set to empty string because <nuxt-link> ignores @click handler
   * `to` value added in handleClick method
   * `target` value is added in handleClick method
   */
    attrs.to = ''
    attrs.href = props.to
    attrs.target = ''
  } else if (tag.value === 'button') {
    attrs.type = props.type
  }

  delete attrs.class

  return attrs
})

const isDisabled = () => {
  return $attrs?.disabled === '' ||
    $attrs?.disabled ||
    $attrs?.['aria-disabled'] === '' ||
    $attrs?.['aria-disabled']
}

const component = computed(() => {
  const state = (() => {
    if (!props.theme) {
      return ''
    }

    const color = props.bgColor || props.theme

    const bgColor = {
      default: getKey(color, {
        default: 'bg-transparent',
        primary: 'bg-primary',
        pink: 'bg-pink-600 text-white',
        secondaryBlue: 'bg-blue-500 text-white'
      }),
      active: !isDisabled && getKey(color, {
        default: '',
        primary: 'active:bg-primary active:text-dark',
        secondary: 'active:text-white active:bg-blue-900',
        secondary2: 'active:text-white active:bg-dark',
        secondaryBlue: 'active:bg-blue-500 active:text-white',
        link: 'active:text-dark',
        tertiary: 'active:text-dark',
        pink: 'active:bg-pink-600 active:text-white'
      })
    }

    const disabledColor = getKey(props.theme, {
      primary: getKey(props.disableTheme, {
        default: 'disabled:shadow-none disabled:bg-gray-400',
        light: 'disabled:bg-gray-300 disabled:text-zinc-400 disabled:border-gray-300 shadow-md'
      }),
      'secondary, secondary2, secondaryBlue': 'disabled:shadow-none disabled:border-gray-400 disabled:bg-gray-400'
    })

    const common = `
      disabled:text-gray-40 disabled:cursor
      ${bgColor.default}
      ${
        ['link', 'tertiary'].includes(props.theme)
          ? ''
          : (!isDisabled && 'active:shadow-inner') || ''
      }
      ${disabledColor}
    `

    const map = {
      primary: `
        rounded-full
        hover:text-white hover:bg-dark hover:shadow-md
        ${bgColor.active} 
        font-bold
      `,
      secondary: `
        border border-dark rounded-full bg-white
        hover:border-white hover:bg-dark hover:text-white hover:shadow-md
        ${bgColor.active}
        font-bold
      `,
      secondary2: `
        border border-gray-400 rounded-lg bg-white
        hover:border-dark hover:bg-dark hover:text-white
        ${bgColor.active} !font-regular hover:!font-bold
      `,
      secondaryBlue: `
        rounded-full
        hover:text-white hover:bg-dark hover:shadow-md
        ${bgColor.active} 
        font-bold
      `,
      secondaryWithImage: `
        border border-gray-400
        rounded-lg bg-white
        hover:border-primary hover:shadow-sm-5
        !p-4 flex-col
      `,
      tertiary: `
        hover:text-primary
        ${bgColor.active} 
        font-bold
      `,
      link: `
        hover:text-primary
        ${bgColor.active}
        font-bold
      `
    }

    if (!props.hasIcon) {
      map.tertiary += ' underline underline-offset-1'
    }

    return removeClassExtras(map[props.theme] + ` ${common}`)
  })()

  const size = (() => {
    if (!props.size || !props.theme) {
      return ''
    }

    const map = {
      'extra small': 'text-xs',
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg leading-[1.625rem]'
    }

    const px = {
      default: ' px-8',
      'extra-small': ' px-6'
    }

    if (props.hasIcon) {
      px.default = ' pl-8 pr-6'
      px['extra-small'] = ' pl-6 pr-4'
    }

    if (props.theme !== 'tertiary') {
      map['extra small'] = ` py-[0.125rem] ${px['extra-small']}`
      map.small += ` py-[0.3125rem] ${px.default}`
      map.medium += ` py-2 ${px.default}`
      map.large += ` py-[0.6875rem] ${px.default}`
    } else {
      map[props.size] += ' p-2'
    }

    return removeClassExtras(map[props.size])
  })()

  const arrowSize = (() => {
    if (!props.size || !props.theme) {
      return ''
    }

    if (!_isEmpty(props.iconSize)) {
      return props.iconSize
    }

    const map = {
      'extra small': {
        height: '10',
        width: '14'
      },
      small: {
        height: '8',
        width: '13'
      },
      medium: {
        height: '10',
        width: '15'
      },
      large: {
        height: '13',
        width: '17'
      }
    }

    return map[props.size]
  })()

  return {
    state,
    size,
    arrowSize
  }
})

const buttonId = $attrs.id || `button_${generateUID()}`
const button = ref(null)

onMounted(() => {
  const el = button?.value

  if (
    tag.value !== 'a' &&
    (el && el.getAttribute('target'))
  ) {
    el.removeAttribute('target')
  }
})

const handleClick = event => {
  try {
    if (props.enableTracking) {
      $tracking.click(event, props.to, props.trackingAdditionalParams)
    }

    if (props.to && tag.value === 'nuxt-link') {
      if ($attrs.target) {
        const link = $router.resolve(props.to)
        window.open(link.href, $attrs.target)
      } else {
        $router.push(props.to)
      }
    }
  } catch (error) {
    throw new Error(error)
  }
}

</script>
